<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Compila sondaggio</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div v-if="loading" class="loading_spinner">
                <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>
            <div class="page_content">
                <div class="sondaggio_container">
                    <div v-if="domandeSondaggio && domandeSondaggio.length > 0">
                        <form @submit.prevent="compilaSondaggio()">
                            <div v-for="(step, i) in domandeSondaggio" :key="i" class="single_step">
                                <div class="single_step_title">{{ step.step }}</div>
                                <div v-for="(domanda, j) in step.domande" :key="j" class="single_field">
                                    <div class="domanda_title">
                                        {{ domanda.sondaggi_domande_domanda }}
                                        <ion-text v-if="domanda.sondaggi_domande_obbligatorio === '1'" color="danger"><strong>*</strong></ion-text>
                                    </div>

                                    <!-- Input type (risposta breve) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta breve'">
                                        <ion-input type="text" v-model="formFields[domanda.sondaggi_domande_id]"> </ion-input>
                                    </div>

                                    <!-- Textarea (risposta paragrafo) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Paragrafo'">
                                        <ion-textarea v-model="formFields[domanda.sondaggi_domande_id]" rows="4"> </ion-textarea>
                                    </div>

                                    <!-- Select (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola'">
                                        <ion-select
                                            interface="action-sheet"
                                            cancel-text="Annulla"
                                            ok-text="Conferma"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ `${option.sondaggi_domande_risposte_risposta}` }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <!-- Radio (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola - radio'">
                                        <ion-radio-group
                                            @ionChange="setRadioRisposta($event, domanda.sondaggi_domande_id)"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-radio
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div>

                                    <!-- Select (scelta multipla) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                        <ion-select
                                            multiple
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ option.sondaggi_domande_risposte_risposta }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <!-- Data -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Data'">
                                        <ion-datetime
                                        name="data"
                                        display-format="DD/MM/YYYY"
                                        picker-format="YYYY-MM-DD"
                                        min="2025"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="formFields[domanda.sondaggi_domande_id]"
                                    ></ion-datetime>
                                    </div>

                                    <!-- Data -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Ora'">
                                        <ion-datetime
                                        name="ora"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        min="2025"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="formFields[domanda.sondaggi_domande_id]"
                                    ></ion-datetime>
                                    </div>
                                </div>
                            </div>

                            <div class="submit">
                                <button type="submit" class="btn_risposte">
                                    Invia risposte
                                </button>
                            </div>
                        </form>
                    </div>


                    <!-- <form @submit.prevent="compilaSondaggio()">
                        <ion-list class="fields">
                            <ion-item v-for="(domanda, index) in formFields" :key="index" lines="none" class="ion-no-padding">
                                <div class="field">
                                    <div class="field_title">
                                        <span>
                                            {{ domanda.label }} {{ domanda.position }}
                                            <ion-text v-if="domanda.required === '1'" color="danger"><strong>*</strong></ion-text>
                                        </span>
                                    </div>
                                    <div class="value">
                                        <ion-input v-if="domanda.type === '1'" type="text" v-model="FormData[domanda.id]"> </ion-input>

                                        <ion-textarea v-if="domanda.type === '3'" v-model="FormData[domanda.id]" rows="3"> </ion-textarea>

                                        <ion-datetime
                                            v-if="domanda.type === '11'"
                                            name="date_start"
                                            display-format="DD/MM/YYYY"
                                            picker-format="DD/MM/YYYY"
                                            min="1900"
                                            max="2099"
                                            slot="end"
                                            doneText="OK"
                                            cancelText="Annulla"
                                            v-model="FormData[domanda.id]"
                                        ></ion-datetime>

                                        <ion-select
                                            v-if="domanda.type === '5'"
                                            :name="domanda.key"
                                            interface="action-sheet"
                                            cancel-text="Annulla"
                                            ok-text="Conferma"
                                            v-model="FormData[domanda.id]"
                                        >
                                            <ion-select-option v-for="(option, index) in domanda.options" :key="index" :value="option.value">
                                                {{ `${option.label}` }}
                                            </ion-select-option>
                                        </ion-select>

                                        <ion-radio-group
                                            v-if="domanda.type === '7'"
                                            :value="domanda.options[0].value"
                                            @ionChange="setRadioRisposta($event, domanda.id)"
                                            v-model="FormData[domanda.id]"
                                        >
                                            <ion-item v-for="(option, index) in domanda.options" :key="index" lines="none" class="ion-no-padding">
                                                <ion-label>{{ `${option.label}` }}</ion-label>
                                                <ion-radio slot="start" :value="option.value"></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div>
                                </div>
                            </ion-item>
                        </ion-list>

                        <div class="submit">
                            <button type="submit" class="btn_risposte">
                                Invia risposte
                            </button>
                        </div>
                    </form> -->
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonInput,
    IonTextarea,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonRadio,
    IonItem,
    IonText,
    IonRadioGroup,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";
import { defineComponent, ref, toRaw, watch, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiSondaggi from "@/services/sondaggi";

export default defineComponent({
    name: "SondaggioDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonSpinner,
        IonIcon,
        IonButton,
        IonInput,
        IonTextarea,
        IonDatetime,
        IonSelect,
        IonSelectOption,
        IonLabel,
        IonRadio,
        IonItem,
        IonText,
        IonRadioGroup,
    },
    setup(props, context) {
        //console.log(props.data);
        const sondaggio = ref([]);
        sondaggio.value = { ...props.data };
        //console.log(sondaggio.value);

        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * 
         * ! Load data
         * 
         */
        const domandeSondaggio = ref([]);
        const loading = ref(false);
        async function getSondaggio() {
            loading.value = true;
            try {
                const response = await apiSondaggi.getSondaggioAppuntamento(sondaggio.value.sondaggi_id);
                //console.log(response);
                if(response.status === 200 && response.data.status === 1) {
                    domandeSondaggio.value = response.data.data;
                } else {
                    openToast("Si è verificato un errore nella richiesta del sondaggio", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore nella richiesta del sondaggio", "toast_danger");
            } finally {
                loading.value = false;
            }
        }


        /**
         * 
         * ! Dynamic form builder and handler
         * 
         */
        const formFields = ref({});
        /**
         * Set form radio based on selected value
         */
        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            formFields.value[reference] = value;
        }

        const savingData = ref(false);
        async function compilaSondaggio() {
            if (sondaggio.value && Object.keys(formFields.value).length === 0) {
                openToast("Non puoi salvare senza aver fornito alcuna risposta", "toast_danger");
                return;
            }

            // Controllo campi obbligatori
            const missingRequiredQuestions = [];
            domandeSondaggio.value.forEach((step) => {
                step.domande.forEach((domanda) => {
                    if (domanda.sondaggi_domande_obbligatorio === "1" && (!formFields.value[domanda.sondaggi_domande_id] || formFields.value[domanda.sondaggi_domande_id].length === 0)) {
                        missingRequiredQuestions.push(domanda.sondaggi_domande_domanda);
                    }
                });
            });
            if (missingRequiredQuestions.length > 0) {
                openToast(`Non hai risposto alle domande obbligatorie: ${missingRequiredQuestions.join(", ")}`, "toast_danger");
                savingData.value = false;
                return;
            }

            const formData = new FormData();
            const rawFormFields = toRaw(formFields.value); // Converte l'oggetto reattivo in un normale oggetto

            Object.keys(rawFormFields).forEach((key) => {
                const value = rawFormFields[key];
                if (Array.isArray(value)) {
                    value.forEach((selectedValue) => formData.append(`risposta[${key}][]`, selectedValue));
                } else {
                    formData.append(`risposta[${key}]`, value);
                }
            });

            formData.append("user_id", userID);
            formData.append("sondaggio_id", sondaggio.value.sondaggi_id);
            formData.append("sondaggio_da_app", 1);

            try {
                savingData.value = true;
                const response = await apiSondaggi.saveCompilazione(formData);
                
                if (response.data.status === 0) {
                    openToast(response.data.txt || "Si è verificato un errore durante il salvataggio delle risposte", "toast_danger");
                } else if (response.data.status === 1) {
                    openToast("Le risposte sono state salvate", "toast_success");
                    closeModal();
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante il salvataggio delle risposte", "toast_danger");
            } finally {
                savingData.value = false;
            }
        }


        // Date formatter
        watch(formFields, (newValues) => {
            for (const key in newValues) {
                if (domandeSondaggio.value.some(step => step.domande.some(d => d.sondaggi_domande_id === key && d.sondaggi_domande_tipologia_value === "Data"))) {
                    if (newValues[key]) {
                        // Extract date from value
                        formFields.value[key] = newValues[key].split("T")[0];
                    }
                }
            }
        }, { deep: true });


        onMounted(() => {
            getSondaggio();
        });

        return {
            dateFormat,
            dateFormatWithMinutes,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            loading,
            FormData,
            compilaSondaggio,
            formFields,
            setRadioRisposta,
            // Nuova versione
            domandeSondaggio,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

/* lead v2*/
.sondaggio_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.sondaggio_container .customer {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.sondaggio_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.sondaggio_container .image_container {
    margin-bottom: 16px;
}
.sondaggio_container .field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    padding-bottom: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.sondaggio_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    padding-bottom: 0;
    border-bottom: none;
}
.sondaggio_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
    width: 100%;
}

/* Form styles */
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    width:100%;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}


ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-left: 12px;
    --padding-right: 12px;
    --placeholder-color: #00000080;
}
ion-select::part(icon) {
    opacity: 1;
    color: #086fa3;
}

ion-input,
ion-textarea {
    --placeholder-color: #00000080;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    --padding-left: 12px !important;
    --padding-right: 12px !important;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    margin-top: 0;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_risposte {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}

.checklist_container {
    margin-top: 32px;
}
.single_step {
    margin-bottom: 32px;
}
.single_step .single_step_title {
    font-weight: bold;
    margin-bottom: 8px;
}
.single_step .single_field {
    margin-bottom: 16px;
}
.single_step .single_field .domanda_title {
    font-size: 14px;
    margin-bottom: 4px;
}

.radio_group_container ion-radio-group {
    display: flex;
    justify-content: flex-start;
    gap: 32px;
}
</style>
