
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonSpinner, IonIcon, modalController, menuController } from "@ionic/vue";
import { refresh, menu, checkmarkCircle, arrowForward } from "ionicons/icons";
import { ref } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiSondaggi from "@/services/sondaggi";

import SondaggioDetail from "@/components/sondaggi/SondaggioDetail.vue";

export default {
    name: "Sondaggi",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonSpinner,
        IonIcon,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const loading = ref(false);
        const prenotazioni = ref([]);
        
        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * Open prenotazione automezzo detail page
         */
        async function openDetailPage(sondaggio) {
            //console.log(sondaggio);
            if(sondaggio.ha_compilato === 1) {
                openToast("Hai già risposto a questo sondaggio", "toast_danger");
                return;
            }

            const modal = await modalController.create({
                component: SondaggioDetail,
                componentProps: {
                    data: sondaggio,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail);
                    //Cerco prenotazione appena modificata (detail non è undefined se ho modificato prenotazione, ho chiuso il form, aggiornato il dettaglio per vedere i dati modificati e poi chiuso anche il dettaglio)
                    const editedPrenotazione = detail.data;
                    const editedIndex = prenotazioni.value.findIndex(
                        (prenotazione) => prenotazione.prenotazioni_automezzi_id == editedPrenotazione.prenotazioni_automezzi_id
                    );
                    if (editedIndex && editedPrenotazione) {
                        prenotazioni.value[editedIndex] = editedPrenotazione;
                    }
                }
            });
            return modal.present();
        }


        /**
         * ! Limit sondaggio title to 35 charactes
         */
         function setShortSondaggioTitle(title) {
            if (!title) {
                return "-";
            } else {
                const trimmedString = title.length > 40 ? title.substring(0, 37) + "..." : title;
                return trimmedString;
            }
        }

        /**
         * ! Load all sondaggi
         */
        const sondaggi = ref([]);
        async function loadSondaggi() {
            loading.value = true;
            try {
                const response = await apiSondaggi.getSondaggi(userID);
                // console.log(response);

                if(response.status === 200) {
                    if(response.data.status === 1) {
                        sondaggi.value = response.data.data;
                    } else {
                        openToast(response.data.txt, "toast_danger");
                    }
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei sondaggi", "toast_danger");
                }
                //console.log(prenotazioni.value);
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei sondaggi", "toast_danger");
            } finally {
                loading.value = false;
            }
        }
        loadSondaggi();


        return {
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            //New field
            refresh,
            loading,
            prenotazioni,
            openDetailPage,
            menu,
            openMenu,
            //Nuovi campi
            loadSondaggi,
            sondaggi,
            setShortSondaggioTitle,
            checkmarkCircle,
            arrowForward,
        };
    },
};
